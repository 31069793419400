import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { addStream } from './sharedActions';
import { addTrack } from './sharedActions';

export interface ContentState {
    remoteStream: MediaStream;
    remoteStreamID: string;
    remoteStreamTrackLength: number;
    remoteTracks: MediaStreamTrack[];
    subscribeError: boolean;
    muted: boolean;
    volume: number;
    playing: boolean;
}

export const initialState: ContentState = {
    remoteStream: null,
    remoteStreamID: '',
    remoteStreamTrackLength: 0,
    remoteTracks: [],
    subscribeError: false,
    muted: false,
    volume: 50,
    playing: false,
};

const contentSlice = createSlice({
    name: 'content',
    initialState,
    reducers: {
        removeStream(state) {
            state.remoteStream = null;
            state.remoteStreamID = '';
            state.remoteStreamTrackLength = 0;
            state.playing = false;
        },
        clearTracks(state) {
            state.remoteTracks = [];
        },
        changeVolume(state, action: PayloadAction<number>) {
            state.muted = action.payload === 0;
            state.volume = action.payload;
        },
        toggleContentMuted(state, action: PayloadAction<boolean>) {
            const nextMuted = typeof action.payload === 'undefined' ? !state.muted : action.payload;
            state.muted = nextMuted;

            if (!nextMuted && state.volume === 0) {
                state.volume = 50;
            }
        },
        contentStreamStarted(state) {
            state.playing = true;
        },
        contentStreamStopped(state) {
            state.playing = false;
        },
        setSubscribeError(state, action: PayloadAction<boolean>) {
            state.subscribeError = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(addStream, (state, action) => {
                const stream = action.payload;
                state.remoteStream = stream;
                state.remoteStreamID = stream.id;
                state.remoteStreamTrackLength = stream.getTracks().length;
            })
            .addCase(addTrack, (state, action) => {
                const track = action.payload;
                state.remoteTracks.push(track);
                state.subscribeError = false;
                state.playing = false;
            });
    },
});

export const {
    changeVolume,
    toggleContentMuted,
    removeStream,
    contentStreamStarted,
    contentStreamStopped,
    setSubscribeError,
} = contentSlice.actions;

export * from './sharedActions';

export default contentSlice.reducer;
